import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { BroadcasterInputEPID, BroadcasterInputProgram, SourceLiveTR101Status } from "@zixi/models";
import _ from "lodash";
import { firstValueFrom, interval, Subscription } from "rxjs";
import { Source } from "src/app/models/shared";
import { PIDMappingProfile } from "src/app/pages/pid-mappings/pid-mapping";
import { BitratePipe } from "src/app/pipes/bitrate.pipe";
import { BytesPipe } from "src/app/pipes/bytes.pipe";
import { TimestampPipe } from "src/app/pipes/timestamp.pipe";
import { SourcesService } from "../../sources.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let $: any;

type BroadcasterInputEPIDWithChanges = BroadcasterInputEPID & { removed?: boolean; set_null?: boolean; was?: number };

type BroadcasterInputProgramWithChanges = Omit<BroadcasterInputProgram, "epids"> & {
    epids: BroadcasterInputEPIDWithChanges[];
    removed?: boolean;
    set_null?: boolean;
    was?: number;
};

type SourceLiveTR101StatusWithChanges = Omit<SourceLiveTR101Status, "programs"> & {
    programs: BroadcasterInputProgramWithChanges[];
};

interface StreamInfoNode {
    id: string;
    text: string;
    parent: string;
    state?: { opened?: boolean };
    a_attr?: { class?: string; title?: string };
}

@Component({
    selector: "app-source-stream-info",
    templateUrl: "./source-stream-info.component.html"
})
export class SourceStreamInfoComponent implements OnChanges, OnDestroy {
    @Input() identifier: string;
    @Input() source: Source;
    @Input() expandPrograms?: boolean = true;
    @Output() resetFunc: EventEmitter<void> = new EventEmitter();

    streamInfo: StreamInfoNode[] = [];
    loading = true;
    refreshInterval = 32000;
    timer$: Subscription;

    constructor(
        private sourcesService: SourcesService,
        private tsp: TimestampPipe,
        private bytesPipe: BytesPipe,
        private bitratePipe: BitratePipe
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        // Check for source changed
        let sourceChanged = false;
        if (
            changes.source &&
            ((!changes.source.previousValue && changes.source.currentValue) ||
                (changes.source.previousValue &&
                    changes.source.currentValue &&
                    changes.source.previousValue.id !== changes.source.currentValue.id))
        ) {
            sourceChanged = true;
        }

        if (sourceChanged) {
            this.loading = true;
            this.stopRefresh();
            this.makeLikeATree();
            this.startRefresh();
        }
    }

    ngOnDestroy() {
        this.stopRefresh();
    }

    reset() {
        this.resetFunc.emit();
    }

    private async makeLikeATree() {
        this.source = this.sourcesService.getCachedSource(undefined, undefined, this.source.id);
        if (!this.source.hasFullDetails) return;

        if (!this.source?.status?.tr101) {
            this.loading = false;
            this.streamInfo = [];
            return;
        }

        if (this.source.type === "pid_map") {
            this.sourcesService.refreshSource(this.source.source_id, false);
            let preMapSource = this.sourcesService.getCachedSource(undefined, undefined, this.source.source_id);

            if (!preMapSource.hasFullDetails)
                preMapSource = await firstValueFrom(this.sourcesService.refreshSource(this.source.source_id, true));

            if (preMapSource.hasFullDetails) {
                this.applyPidMappigChanges(
                    preMapSource.status.tr101,
                    this.source.status.tr101,
                    this.source.pid_mapping
                );
            }
        }

        const streamInfo = this.buildTree(this.source.status.tr101, this.streamInfo);
        if (_.isEqual(streamInfo, this.streamInfo)) {
            this.loading = false;
            return;
        }

        this.streamInfo = streamInfo;

        $(() => {
            $("#stream_info-" + this.identifier).jstree({
                core: {
                    multiple: false,
                    animation: true,
                    check_callback: false,
                    dblclick_toggle: true,
                    themes: {
                        icons: false
                    },
                    data: this.streamInfo
                },
                version: 1
            });

            $("#stream_info-" + this.identifier).jstree(true).settings.core.data = this.streamInfo;
            $("#stream_info-" + this.identifier)
                .jstree(true)
                .refresh();
        });

        this.loading = false;
    }

    private startRefresh() {
        this.timer$ = interval(this.refreshInterval).subscribe(async () => {
            await this.makeLikeATree();
        });
    }

    private stopRefresh() {
        if (this.timer$) this.timer$.unsubscribe();
    }

    private applyPidMappigChanges(
        preTr101Data: Partial<SourceLiveTR101StatusWithChanges>,
        postTr101Data: Partial<SourceLiveTR101StatusWithChanges>,
        profile: PIDMappingProfile
    ) {
        for (const preProgram of preTr101Data.programs) {
            const programRule = profile.rules.find(
                rule => rule.source === "pmt" && rule.program_number == preProgram.general.number
            );

            const action = programRule?.action ?? profile.default_action;

            if (action === "remove") {
                const postProgram = postTr101Data.programs?.find(p => p.general.number === preProgram.general.number);

                if (!postProgram) postTr101Data.programs?.unshift({ ...preProgram, removed: true });
                continue;
            }

            if (action === "set_null") {
                const postProgram = postTr101Data.programs?.find(p => p.general.number === preProgram.general.number);

                if (!postProgram) postTr101Data.programs?.unshift({ ...preProgram, set_null: true });
                continue;
            }

            if (action === "map") {
                const postProgram = postTr101Data.programs?.find(
                    p => p.general.number === programRule.target_program_number
                );

                if (postProgram) postProgram.was = preProgram.general.number;
            }

            for (const prePID of preProgram.epids) {
                const pidRule = profile.rules.find(rule =>
                    profile.type === "category"
                        ? this.matchByCategory(rule.source, prePID.type)
                        : profile.type === "type"
                        ? rule.source === prePID.type
                        : this.parsePidMapRuleSourcePIDs(rule.source).includes(prePID.pid)
                );

                const action = pidRule?.action ?? profile.default_action;

                if (action === "remove") {
                    const postProgram = postTr101Data.programs?.find(
                        p => p.general.number === preProgram.general.number
                    );
                    const postPID = postProgram?.epids?.find(p => p.pid === prePID.pid);

                    if (!postPID) postProgram?.epids?.unshift({ ...prePID, removed: true });
                    continue;
                }

                if (action === "set_null") {
                    const postProgram = postTr101Data.programs?.find(
                        p => p.general.number === preProgram.general.number
                    );
                    const postPID = postProgram?.epids?.find(p => p.pid === prePID.pid);

                    if (!postPID) postProgram?.epids?.unshift({ ...prePID, set_null: true });
                    continue;
                }

                if (action === "map" && pidRule) {
                    const postProgram = postTr101Data.programs?.find(
                        p => p.general.number === preProgram.general.number
                    );
                    const postPID = postProgram?.epids?.find(p => p.pid === pidRule.target_pid);

                    if (postPID) postPID.was = prePID.pid;
                    continue;
                }
            }
        }
    }

    private matchByCategory(category: string, pidType: string): boolean {
        switch (pidType) {
            case "mpgv1":
            case "mpgv2":
            case "h264":
            case "hevc":
            case "j2k":
                return category === "video";
            case "ac3":
            case "aac_latm":
            case "aac_adts":
            case "mpga1":
            case "mpga2":
            case "dts6":
            case "dolby_hd":
            case "dolby_digital_blueray":
            case "dolby_digital_atsc":
            case "dts8":
            case "opus":
            case "aes3":
                return category === "audio";
            case "scte35":
                return category === "scte35";
            default:
                return false;
        }
    }

    private parsePidMapRuleSourcePIDs(pidMapSource: string): number[] {
        return pidMapSource.split(",").reduce((result, source) => {
            const [from, to] = source.split("-").map(s => +s);
            if (to) {
                for (let i = from; i <= to; ++i) result.push(i);
            } else {
                result.push(from);
            }
            return result;
        }, [] as number[]);
    }

    private buildTree(
        tr101Data: Partial<SourceLiveTR101StatusWithChanges>,
        currentTree?: StreamInfoNode[]
    ): StreamInfoNode[] {
        if (!tr101Data || !tr101Data.transport) return [];
        const totalBitrate = tr101Data.bitrate;

        // add pat info
        const pat: StreamInfoNode[] = [{ id: "pat", text: "PAT", parent: "#" }].concat(
            this.create_generic_pid_data(tr101Data.transport.pat, {
                parent: "pat",
                total_packets: tr101Data.transport.packets,
                print_min_br: true,
                print_max_br: true,
                print_avg_br: true,
                print_cc: true,
                print_scrambled: true
            })
        );

        let nullNode: StreamInfoNode[] = [];
        if (tr101Data.transport.null_pid) {
            nullNode = [{ id: "null", text: "NULL", parent: "#" }].concat(
                this.create_generic_pid_data(tr101Data.transport.null_pid, {
                    parent: "null",
                    total_packets: tr101Data.transport.packets,
                    print_min_br: true,
                    print_max_br: true,
                    print_avg_br: true
                })
            );
        }

        let programs: StreamInfoNode[] = [];
        if (tr101Data.programs) {
            const programsData = tr101Data.programs;

            programs.push({
                id: "programs",
                text: "Programs" + " (" + tr101Data.programs.length + ")",
                parent: "#",
                state: { opened: true }
            });

            for (const programData of programsData.sort((a, b) => a.general.number - b.general.number)) {
                const programNode: StreamInfoNode = {
                    id: "programs_" + programData.general.number,
                    text: "Program #" + programData.general.number,
                    parent: "programs",
                    state: { opened: this.expandPrograms },
                    a_attr: {}
                };

                if (programData.general.name?.length > 0) programNode.text += ` - ${programData.general.name}`;

                if (programData.removed) {
                    programNode.text += " (Removed)";
                    programNode.a_attr = { class: "tr101-tree-pid-removed" };

                    programs = programs.concat([programNode]);
                    continue;
                }

                if (programData.set_null) {
                    programNode.text += " (Set NULL)";
                    programNode.a_attr = { class: "tr101-tree-pid-set-null" };

                    programs = programs.concat([programNode]);
                    continue;
                }

                if (programData.was != null) {
                    programNode.text += ` (Was #${programData.was})`;
                    programNode.a_attr = { class: "tr101-tree-pid-was" };
                }

                const pmtPidNode = [
                    {
                        id: "pmt_programs_" + programData.general.number,
                        text: "PMT PID: " + this.print_pid_number(programData.general.pmt_pid),
                        parent: programNode.id
                    }
                ].concat(
                    this.create_generic_pid_data(programData.pmt_pid, {
                        parent: "pmt_programs_" + programData.general.number,
                        total_packets: tr101Data.transport.packets,
                        print_min_br: true,
                        print_max_br: true,
                        print_avg_br: true,
                        print_cc: true
                    })
                );

                let pcrPidNode = [];
                if (tr101Data.pcrs) {
                    for (const pcr of tr101Data.pcrs) {
                        if (pcr.pid !== programData.general.pcr_pid) continue;

                        const pcrInfo = pcr;
                        const pcrNodeId = "pcr_programs_" + programData.general.number;

                        pcrPidNode = [
                            {
                                id: pcrNodeId,
                                text: "PCR PID: " + this.print_pid_number(pcrInfo.pid),
                                parent: programNode.id
                            },
                            {
                                id: pcrNodeId + "_acc",
                                text: ["Accuracy:", this.roundNumber(pcrInfo.accuracy, 1), "ns"].join(" "),
                                parent: pcrNodeId
                            },
                            {
                                id: pcrNodeId + "_dev",
                                text: ["Deviation:", this.roundNumber(pcrInfo.deviation, 1), "ns"].join(" "),
                                parent: pcrNodeId
                            },
                            {
                                id: pcrNodeId + "_del",
                                text: ["Delay:", this.roundNumber(pcrInfo.delay, 1), "ns"].join(" "),
                                parent: pcrNodeId
                            },
                            {
                                id: pcrNodeId + "_ojmax",
                                text: ["Oj Max:", this.roundNumber(pcrInfo.OjMax, 1), "ns"].join(" "),
                                parent: pcrNodeId
                            },
                            {
                                id: pcrNodeId + "_ojdev",
                                text: ["Oj Dev:", this.roundNumber(pcrInfo.OjDev, 1), "ns"].join(" "),
                                parent: pcrNodeId
                            },
                            {
                                id: pcrNodeId + "_br",
                                text: ["Bitrate:", pcrInfo.bitrate / 1000, "ns"].join(" "),
                                parent: pcrNodeId
                            }
                        ];

                        break;
                    }
                }

                const elementaryPidsNodeId = "epids_programs_" + programData.general.number;
                const elementaryPidsNode = {
                    id: elementaryPidsNodeId,
                    text: "Elementary PIDs",
                    parent: programNode.id,
                    state: { opened: true }
                };
                let elementaryPidsNodes = [];
                for (const epid of programData.epids.sort((a, b) => a.pid - b.pid)) {
                    const currentEpidNodeId = elementaryPidsNodeId + "_pid_" + epid.pid;

                    let currentEpidNode: {
                        id: string;
                        text: string;
                        parent: string;
                        state?: Record<string, unknown>;
                        a_attr?: Record<string, unknown>;
                    }[] = [
                        {
                            id: currentEpidNodeId,
                            text: ["PID:", this.print_pid_number(epid.pid)].join(" "),
                            parent: elementaryPidsNodeId,
                            state: { opened: false },
                            a_attr: {}
                        }
                    ];

                    if (epid.removed) {
                        currentEpidNode[0].text += ` ${epid.type} (Removed)`;
                        currentEpidNode[0].a_attr = { class: "tr101-tree-pid-removed" };

                        elementaryPidsNodes = elementaryPidsNodes.concat(currentEpidNode);
                        continue;
                    }

                    if (epid.set_null) {
                        currentEpidNode[0].text += ` ${epid.type} (Set NULL)`;
                        currentEpidNode[0].a_attr = { class: "tr101-tree-pid-set-null" };

                        elementaryPidsNodes = elementaryPidsNodes.concat(currentEpidNode);
                        continue;
                    }

                    if (epid.was != null) {
                        currentEpidNode[0].text += ` (Was ${epid.was})`;
                        currentEpidNode[0].a_attr = { class: "tr101-tree-pid-was" };
                    }

                    currentEpidNode = currentEpidNode.concat(
                        this.create_generic_pid_data(epid, {
                            parent: currentEpidNodeId,
                            total_packets: tr101Data.transport.packets,
                            print_type: true,
                            print_min_br: true,
                            print_max_br: true,
                            print_avg_br: true,
                            print_cc: true,
                            print_scrambled: true,
                            print_stream_id: true,
                            print_bytes: true
                        })
                    );

                    if (epid.sample_rate) {
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_sr",
                            text: ["Sample rate: ", epid.sample_rate].join(""),
                            parent: currentEpidNodeId
                        });
                    } else if (epid.width || epid.height || epid.FPS) {
                        currentEpidNode[0].state = { opened: true };

                        //  Assuming its video, and the following fields are present.
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_width",
                            text: ["Width: ", epid.width].join(""),
                            parent: currentEpidNodeId
                        });
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_height",
                            text: ["Height: ", epid.height].join(""),
                            parent: currentEpidNodeId
                        });
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_fps",
                            text: ["FPS: ", epid.FPS].join(""),
                            parent: currentEpidNodeId
                        });
                        if (epid.pts != null)
                            currentEpidNode.push({
                                id: currentEpidNodeId + "_pts",
                                text: ["PTS: ", this.tsp.transform(epid.pts / 90)].join(""),
                                parent: currentEpidNodeId
                            });
                        if (epid.dts != null)
                            currentEpidNode.push({
                                id: currentEpidNodeId + "_dts",
                                text: ["DTS: ", this.tsp.transform(epid.dts / 90)].join(""),
                                parent: currentEpidNodeId
                            });
                        if (epid.chroma != null)
                            currentEpidNode.push({
                                id: currentEpidNodeId + "_chroma",
                                text: ["Chroma: ", epid.chroma].join(""),
                                parent: currentEpidNodeId
                            });
                        if (epid.interlaced != null)
                            currentEpidNode.push({
                                id: currentEpidNodeId + "_interlaced",
                                text: ["Interlaced: ", epid.interlaced ? "Yes" : "No"].join(""),
                                parent: currentEpidNodeId
                            });
                    }

                    if (epid.bit_depth_chroma != null)
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_bit_depth_chroma",
                            text: ["Chroma bit depth: ", epid.bit_depth_chroma].join(""),
                            parent: currentEpidNodeId
                        });

                    if (epid.bit_depth_luma != null)
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_bit_depth_luma",
                            text: ["Luma bit depth: ", epid.bit_depth_luma].join(""),
                            parent: currentEpidNodeId
                        });

                    if (epid.good_frames != null)
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_frames",
                            text: ["Frames: ", epid.good_frames].join(""),
                            parent: currentEpidNodeId
                        });

                    if (epid.frame_duration_maximum != null && epid.frame_duration_maximum !== 0) {
                        const fdmax = Math.round((epid.frame_duration_maximum / 90 + Number.EPSILON) * 100) / 100;
                        const fdmin = Math.round((epid.frame_duration_minimum / 90 + Number.EPSILON) * 100) / 100;
                        const fdavg = Math.round((epid.frame_duration_average / 90 + Number.EPSILON) * 100) / 100;

                        currentEpidNode.push({
                            id: currentEpidNodeId + "_frame_duration",
                            text: ["Frame Duration (ms): ", fdmax, "/", fdmin, "/", fdavg].join(""),
                            parent: currentEpidNodeId,
                            a_attr: { title: "Frame Duration (ms): Max/Min/Average" }
                        });
                    }

                    if (epid.gop_structure)
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_gop_structure",
                            text: ["GOP structure: ", epid.gop_structure.substring(0, 10)].join(""),
                            parent: currentEpidNodeId
                        });

                    if (epid.gop_duration_maximum != null && epid.gop_duration_maximum !== 0) {
                        const gdmax = Math.round((epid.gop_duration_maximum / 90 + Number.EPSILON) * 100) / 100;
                        const gdmin = Math.round((epid.gop_duration_minimum / 90 + Number.EPSILON) * 100) / 100;
                        const gdavg = Math.round((epid.gop_duration_average / 90 + Number.EPSILON) * 100) / 100;

                        currentEpidNode.push({
                            id: currentEpidNodeId + "_gop_duration",
                            text: ["GOP Duration (ms): ", gdmax, "/", gdmin, "/", gdavg].join(""),
                            parent: currentEpidNodeId,
                            a_attr: { title: "GOP Duration (ms): Max/Min/Average" }
                        });
                    }

                    if (epid.cc_sei_messages != null)
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_cc_sei_messages",
                            text: ["CEA-608/708 captions: ", epid.cc_sei_messages].join(""),
                            parent: currentEpidNodeId
                        });

                    if (epid.buffer_size_sps != null) {
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_buffer_size_sps",
                            text: ["HRD buffer size: ", this.bytesPipe.transform(epid.buffer_size_sps, "1.0-0")].join(
                                ""
                            ),
                            parent: currentEpidNodeId
                        });
                    }

                    if (epid.bitrate_sps != null) {
                        currentEpidNode.push({
                            id: currentEpidNodeId + "_bitrate_sps",
                            text: ["Declared Bitrate: ", this.bitratePipe.transform(epid.bitrate_sps)].join(""),
                            parent: currentEpidNodeId
                        });
                    }

                    elementaryPidsNodes = elementaryPidsNodes.concat(currentEpidNode);
                }

                programs = programs.concat(
                    [programNode],
                    pmtPidNode,
                    pcrPidNode,
                    [elementaryPidsNode],
                    elementaryPidsNodes
                );
            }
        }

        const nodes = ([] as StreamInfoNode[]).concat(pat, nullNode, programs);

        if (this.streamInfo?.length > 0)
            for (const node of nodes) {
                if (!node.state) node.state = {};
                const currentNode = this.getNodeOpen(node.id);
                node.state.opened = currentNode?.state?.opened ?? node.state.opened ?? false;
            }

        return nodes;
    }

    private getNodeOpen(id: string) {
        const node = $("#stream_info-" + this.identifier)
            .jstree(true)
            .get_node(id);
        return node;
    }

    private create_generic_pid_data(
        data: Partial<BroadcasterInputEPID>,
        config: Partial<{
            parent: string;
            total_packets: number;
            print_min_br: boolean;
            print_max_br: boolean;
            print_avg_br: boolean;
            print_cc: boolean;
            print_scrambled: boolean;
            print_type: boolean;
            print_stream_id: boolean;
            print_bytes: boolean;
        }>
    ): StreamInfoNode[] {
        const result: StreamInfoNode[] = [];
        if (!data) return result;

        if (config.print_type)
            result.push({
                id: config.parent + "_pid_type",
                text: [
                    "Type: ",
                    data.type,
                    config.print_stream_id ? [" (#", data.stream_id.toString(16).toUpperCase(), ")"].join("") : ""
                ].join(""),
                parent: config.parent
            });

        if (config.print_min_br)
            result.push({
                id: config.parent + "_min_br",
                text: ["Min bitrate: ", this.print_pid_bitrate(data.min_bitrate)].join(""),
                parent: config.parent
            });

        if (config.print_max_br)
            result.push({
                id: config.parent + "_max_br",
                text: ["Max bitrate: ", this.print_pid_bitrate(data.max_bitrate)].join(""),
                parent: config.parent
            });

        if (config.print_avg_br)
            result.push({
                id: config.parent + "_avg_br",
                text: ["Avg bitrate: ", this.print_pid_bitrate(data.average_bitrate)].join(""),
                parent: config.parent
            });

        if (config.print_cc)
            result.push({
                id: config.parent + "_cc_err",
                text: ["CC errors: ", data.CC_errors].join(""),
                parent: config.parent
            });

        if (config.print_scrambled)
            result.push({
                id: config.parent + "_scrambled",
                text: ["Scrambled: ", data.scrambled].join(""),
                parent: config.parent
            });

        if (config.print_bytes)
            result.push({
                id: config.parent + "_bytes",
                text: ["Bytes: ", data.bytes].join(""),
                parent: config.parent
            });
        if (config.print_bytes)
            result.push({
                id: config.parent + "_packets",
                text: ["Packets: ", this.print_pid_packets(data.packets, config.total_packets)].join(""),
                parent: config.parent
            });

        return result;
    }

    private roundNumber(rnum: number, rlength: number) {
        const newnumber = Math.round(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
        return newnumber;
    }

    private print_pid_bitrate(bitrate: number, totalBitrate?: number) {
        const str = [];
        str.push([Math.floor(bitrate / 1000), " kbps"].join(""));
        if (totalBitrate) {
            str.push([" (", this.roundNumber((100 * bitrate) / totalBitrate, 1), "%)"].join(""));
        }

        return str.join("");
    }

    private print_pid_packets(packetes: number, totalPackets?: number) {
        const str: string[] = [];
        str.push(packetes.toString());
        if (totalPackets) str.push(`(${this.roundNumber((100 * packetes) / totalPackets, 1)}%)`);
        return str.join(" ");
    }

    private print_pid_number(pid: number) {
        return [pid, " (0x", pid.toString(16).toUpperCase(), ")"].join("");
    }
}
