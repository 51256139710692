import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { SourceListComponent } from "./source-list/source-list.component";
import { SourceComponent } from "./source/source.component";
import { MediaConnectSourceComponent } from "./mc-source/mc-source.component";
import { SourceFormComponent } from "./source-form/source-form.component";
import { SourceFormZixiComponent } from "./source-form-zixi/source-form.component";
import { SourceFormToMediaConnectComponent } from "./source-form-to-mediaconnect/source-form.component";
import { SourceFormMonitorComponent } from "./source-form-monitor/source-form.component";
import { SourceFormFileComponent } from "./source-form-file/source-form.component";
import { SourceFormTranscodedComponent } from "./source-form-transcoded/source-form.component";
import { SourceFormNdiComponent } from "./source-form-ndi/source-form.component";
import { SourceFormFailoverComponent } from "./source-form-failover/source-form.component";
import { SourceFormInterClusterComponent } from "./source-form-inter-cluster/source-form.component";
import {
    SourceListResolverService,
    MediaConnectSourceListResolverService
} from "./source-list/source-list-resolver.service";
import { SourceFormUDPRTPComponent } from "./source-form-udp-rtp/source-form.component";
import { SourceFormSRTComponent } from "./source-form-srt/source-form.component";
import { SourceFormRTMPComponent } from "./source-form-rtmp/source-form.component";
import { SourceFormRISTComponent } from "./source-form-rist/source-form.component";
import { SourceFormHLSPullComponent } from "./source-form-hls-pull/source-form.component";
import { SourceFormPidMappingComponent } from "./source-form-pid-mapping/source-form.component";
import { SourceWebrtcVideoComponent } from "./source/source-webrtc-video/source-webrtc-video.component";
import { SourceElementalLinkComponent } from "./source-form-elemental-link/source-form.component";
import { SourceFormDemuxComponent } from "./source-form-demux/source-form.component";
//
import { SourceResolverService } from "./source/source-resolver.service";
import { SourceFormMultiviewComponent } from "./source-form-multiview/source-form.component";
import { SourceFormMultiplexComponent } from "./source-form-multiplex/source-form.component";
import {
    MediaConnectSourceBreadcrumbsResolverService,
    MediaConnectSourceFormBreadcrumbsResolverService,
    SourceBreadcrumbsResolverService,
    SourceFormBreadcrumbsResolverService
} from "./source-resolver.service";

const sourceRoutes: Routes = [
    {
        path: Constants.urls.sources + "/new",
        component: SourceFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/O4CPG"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/zixi/new",
        component: SourceFormZixiComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Zixi Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_ZEC_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/OYBUGgE"
                },
                {
                    menu: "HELP_MENU.NEW_FEEDER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/6tgpMwf0"
                },
                {
                    menu: "HELP_MENU.NEW_BROADCASTER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/p1pQCbPu"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/DXaD6pr6"
                },
                {
                    menu: "HELP_MENU.NEW_OTHER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/zp1GnQy6"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/monitor_only/new",
        component: SourceFormMonitorComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Monitor Only Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_MONITOR_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/99jaZmYS"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/file/new",
        component: SourceFormFileComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New File Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_FILE_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/FwAfag"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/transcoded/new",
        component: SourceFormTranscodedComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Transcoded Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_TRANSCODED_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/dZ2fPi8P"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/ndi/new",
        component: SourceFormNdiComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New NDI Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_NDI_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/itBS1XH1"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/pid-mapping/new",
        component: SourceFormPidMappingComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New PID Mapping Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_PIDMAP_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/U6Fu54Ca"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/hitless/new",
        component: SourceFormFailoverComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Failover Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_FAILOVER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/ucubpkiP"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/inter-cluster/new",
        component: SourceFormInterClusterComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Inter-Cluster Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_INTERCLUSTER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/3XHeFFWf"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/mediaconnect/new",
        component: SourceFormToMediaConnectComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New AWS Media Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_TO_MEDIACONNECT_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/PX0hKr15"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/udp-rtp/new",
        component: SourceFormUDPRTPComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New UDP/RTP Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_UDPRTP_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/1asE2P6y"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/srt/new",
        component: SourceFormSRTComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New SRT Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_SRT_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/0uXc628j"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/rist/new",
        component: SourceFormRISTComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New RIST Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_RIST_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/rtmp/new",
        component: SourceFormRTMPComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New RTMP Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_RTMP_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/hls_pull/new",
        component: SourceFormHLSPullComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New HLS Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_HLS_PULL_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/elemental_link/new",
        component: SourceElementalLinkComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New AWS Elemental Link Source"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/demux/new",
        component: SourceFormDemuxComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Demux Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_DEMUX_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYDoKAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/multiview/new",
        component: SourceFormMultiviewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Multiview Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_MULTIVIEW_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/IABMKAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/multiplex/new",
        component: SourceFormMultiplexComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                },
                {
                    label: "New Source",
                    url: `/${Constants.urls.sources}/new`
                },
                {
                    label: "New Multiplex Source"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_MULTIPLEX_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYAaKQE"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/zixi/:sourceId/:name/:action",
        component: SourceFormZixiComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_ZEC_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/OYBUGgE"
                },
                {
                    menu: "HELP_MENU.NEW_FEEDER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/6tgpMwf0"
                },
                {
                    menu: "HELP_MENU.NEW_BROADCASTER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/p1pQCbPu"
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/DXaD6pr6"
                },
                {
                    menu: "HELP_MENU.NEW_OTHER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/zp1GnQy6"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/monitor_only/:sourceId/:name/:action",
        component: SourceFormMonitorComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MONITOR_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/99jaZmYS"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/file/:sourceId/:name/:action",
        component: SourceFormFileComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_FILE_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/FwAfag"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/transcoded/:sourceId/:name/:action",
        component: SourceFormTranscodedComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_TRANSCODED_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/dZ2fPi8P"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/ndi/:sourceId/:name/:action",
        component: SourceFormNdiComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_NDI_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/itBS1XH1"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/pid-mapping/:sourceId/:name/:action",
        component: SourceFormPidMappingComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_PIDMAP_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/U6Fu54Ca"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/hitless/:sourceId/:name/:action",
        component: SourceFormFailoverComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_FAILOVER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/ucubpkiP"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/inter-cluster/:sourceId/:name/:action",
        component: SourceFormInterClusterComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_INTERCLUSTER_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/3XHeFFWf"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/mediaconnect/:name/:action",
        component: SourceFormToMediaConnectComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: MediaConnectSourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_TO_MEDIACONNECT_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/PX0hKr15"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/udp-rtp/:sourceId/:name/:action",
        component: SourceFormUDPRTPComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_UDPRTP_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/1asE2P6y"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/srt/:sourceId/:name/:action",
        component: SourceFormSRTComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_SRT_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/0uXc628j"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/rist/:sourceId/:name/:action",
        component: SourceFormRISTComponent,
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_RIST_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/rtmp/:sourceId/:name/:action",
        component: SourceFormRTMPComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_RTMP_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/hls_pull/:sourceId/:name/:action",
        component: SourceFormHLSPullComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_HLS_PULL_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/multiview/:sourceId/:name/:action",
        component: SourceFormMultiviewComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MULTIVIEW_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/IABMKAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/elemental_link/:name/:action",
        component: SourceElementalLinkComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: MediaConnectSourceFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.sources + "/demux/:sourceId/:name/:action",
        component: SourceFormDemuxComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_DEMUX_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYDoKAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/multiplex/:sourceId/:name/:action",
        component: SourceFormMultiplexComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MULTIPLEX_SOURCE",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYAaKQE"
                }
            ]
        }
    },
    {
        path: Constants.urls.sources + "/:sourceId/:name/webrtc",
        component: SourceWebrtcVideoComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: SourceFormBreadcrumbsResolverService
        }
    },

    {
        path: Constants.urls.sources,
        component: SourceListComponent,
        canActivate: [AuthGuard],
        resolve: {
            source: SourceListResolverService,
            mcSource: MediaConnectSourceListResolverService
        },
        children: [
            {
                path: "mediaconnect/:name",
                component: MediaConnectSourceComponent,
                resolve: {
                    breadcrumbs: MediaConnectSourceBreadcrumbsResolverService
                }
            },
            {
                path: "elemental_link/:name",
                component: SourceElementalLinkComponent,
                resolve: {
                    breadcrumbs: MediaConnectSourceBreadcrumbsResolverService
                }
            },
            {
                path: ":sourceId/:name",
                component: SourceComponent,
                resolve: {
                    source: SourceResolverService,
                    breadcrumbs: SourceBreadcrumbsResolverService
                }
            }
        ],
        data: {
            breadcrumbs: [
                {
                    label: "Sources",
                    url: `/${Constants.urls.sources}`
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.SOURCES",
                    link: "https://zixidocumentation.atlassian.net/l/c/HAVbKFk0"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(sourceRoutes)],
    exports: [RouterModule]
})
export class SourcesRouting {}
