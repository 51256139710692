import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { ClustersService } from "../../clusters/clusters.service";
import { SharedService } from "../../../services/shared.service";

import { Source, InputNIC } from "../../../models/shared";
import { Cluster } from "../../clusters/cluster";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { UsersService } from "../../account-management/users/users.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-source-form-udp-rtp",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormUDPRTPComponent implements OnInit {
    source: Source;
    private sources: Source[];
    sourceNames: string[];
    inputNICs: InputNIC[] = [];

    private existingSource: Source;
    private action: string;
    loading = true;
    saving = false;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    targetNICLoading = false;
    constants = Constants;

    useRtp = false;

    maxBitrates = Constants.maxBitrates;

    accountTracerouteHistory: boolean;

    targetBXsLoading = false;
    targetBXs = [];
    private targetBXsBase = this.targetBXs;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ss = inject(SourcesService);
    private broadcastersService = inject(BroadcastersService);
    private clusterService = inject(ClustersService);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private userService = inject(UsersService);

    private prepForm() {
        if (this.action) {
            if (this.source.dejitter_clock === null) this.source.dejitter_clock = 0;
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                if (this.source.broadcaster_cluster_id != null) {
                    this.clusterSelectionChange(this.source.broadcaster_cluster_id, false);
                }
                if (this.source.target_broadcaster_id > 0) {
                    this.getBroadcasterDetails(this.source.target_broadcaster_id);
                }

                if (this.source.protocol === "rtp") this.useRtp = true;
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.source.type = "udp";
            this.source.protocol = "udp";
            this.source.dejitter_clock = 0;
            this.useRtp = false;
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    private resetForm() {
        // Source
        this.source.latency = 4000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.traceroute_history = 0;
        this.source.output_nic = "";
        this.tagsControl.setValue([]);
        this.source.broadcaster_cluster_id = null;
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.nameControl.setValue(null);
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.pid_mapping_profile_id = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        // this.source.webrtc_thumbnail = 0;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;

        // UI
        this.useRtp = this.source.protocol === "rtp";
    }

    async clusterSelectionChange(id: number, clusterHasChanged: boolean) {
        //  Clear specific broadcser selection since cluster is about to change.
        //XXXAmit Why not await or other async verb?
        if (clusterHasChanged && this.source && this.source.target_broadcaster_id >= 0)
            this.source.target_broadcaster_id = undefined;

        this.getClusterSourceNames(id);
        this.getTargetBroadcasters(id);
    }

    private getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    private async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }
        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = this.targetBXsBase.concat(
                _.map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }
        this.targetBXsLoading = false;
    }

    async getBroadcasterDetails(id: number) {
        this.targetNICLoading = true;
        this.inputNICs = [];

        const broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();

        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.nics) {
                this.inputNICs = broadcaster.status.nics || [{ name: "Any", value: "", nic: "" }];
                if (!this.source.input_nic) this.source.input_nic = "";
            } else {
                this.inputNICs = [{ name: "Any", value: "", nic: "" }];
                this.source.input_nic = "";
            }
        } else {
            this.inputNICs = [{ name: "Any", value: "", nic: "" }];
            this.source.input_nic = "";
        }
        this.targetNICLoading = false;
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const sourceName = params.name;
        const sourceId = urlBuilder.decode(params.sourceId);
        this.action = params.action;

        await firstValueFrom(this.ss.refreshSources(true));
        this.sources = await firstValueFrom(this.ss.sources);
        if (sourceName && sourceId) {
            this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));

            // Check if source found in cache, if not get sources and source
            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                await firstValueFrom(this.ss.refreshSource(this.source));
                this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));
            }
            this.existingSource = _.cloneDeep(this.source);
        }
        this.accountTracerouteHistory = await firstValueFrom(this.userService.isTracerouteHistory);
        this.prepForm();
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;

        const sourceModel = {
            name: this.isEdit ? undefined : this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            feeder_id: null,
            broadcaster_id: null,
            target_broadcaster_id: this.source.target_broadcaster_id,
            monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
            traceroute_history: this.source.traceroute_history ? 1 : 0,
            content_analysis: this.source.content_analysis ? 1 : 0,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            allow_outputs: this.source.allow_outputs ? 1 : 0,
            outputs_password: this.source.outputs_password || "",
            protocol: this.useRtp ? "rtp" : "udp",
            listening_port: this.source.listening_port,
            max_bitrate: this.source.max_bitrate,
            input_nic: this.source.input_nic && this.source.input_nic !== "" ? this.source.input_nic : null,
            bind_input_device: this.source.bind_input_device ? 1 : 0,
            multicast_address: this.source.multicast_address === "" ? null : this.source.multicast_address,
            multicast_source: this.source.multicast_source === "" ? null : this.source.multicast_source,
            use_fec: this.source.use_fec,
            pid_mapping_profile_id: this.source.pid_mapping_profile_id,
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            webrtc_mode: this.source.webrtc_mode,
            location: this.source.location.address,
            muted: this.source.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.source.is_enabled,
            // webrtc_thumbnail: this.source.webrtc_thumbnail
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            keep_rtp_headers: this.source.keep_rtp_headers,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion,
            dejitter_buffer: this.source.dejitter_buffer,
            dejitter_clock: this.source.dejitter_clock
        };

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const ignores = ["name"];
            const changedData = this.sharedService.getZixiObjDiff(sourceModel, this.existingSource, ignores);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart udp/rtp  source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update udp/rtp  source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(sourceModel);
            if (result) {
                this.mixpanelService.sendEvent("create udp/rtp source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }
    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }
    onUseRtpChange() {
        this.source.protocol = this.useRtp ? "rtp" : "udp";
        if (!this.useRtp) {
            this.source.dejitter_clock = 0;
        }
    }
}
