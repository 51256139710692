import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants/constants";
import { SourceDetailsComponent } from "./source-details/source-details.component";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { ZxHistoryGraphComponent } from "src/app/components/shared/zx-history-graph/zx-history-graph.component";
import { ZxEventsComponent } from "src/app/components/shared/zx-events/zx-events.component";
import { SourceSourcesComponent } from "./source-sources/source-sources.component";
import { SourceContentAnalysisComponent } from "./source-content-analysis/source-content-analysis.component";
import { ZxObjectTrackingMainListComponent } from "src/app/components/shared/zx-object-tracking/main-list/zx-object-tracking-main-list.component";
import { ObjectIncidentListComponent } from "src/app/components/shared/object-incidents/object-incident-list.component";
import { SourceBondingInfoComponent } from "./source-bonding-info/source-bonding-info.component";
import { ZxScteComponent } from "src/app/components/shared/zx-scte/zx-scte.component";
import { SharedResourceListComponent } from "src/app/components/shared/shared-resource-list/shared-resource-list.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import { SourceStreamComponent } from "./source-stream/source-stream.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import { GraphsService } from "src/app/services/graphs.service";
import { Source } from "src/app/models/shared";
import { BaseLayout } from "src/app/helpers/base-layout";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "src/app/services/shared.service";
import { ReactFlowDiagramComponent } from "src/app/components/shared/react-flow-diagram/react-flow-diagram.component";

export abstract class SourceLayouts extends BaseLayout {
    keys = {
        primaryDetails: [
            //The order is important for the layout
            "THUMBNAIL",
            "BITRATE",
            "UP_TIME",
            "INGEST_CLUSTER",
            "PRIORITY",
            "PLAY",
            "TAGS"
        ],
        secondaryDetails: [
            //The order is important for the layout
            "TYPE",
            "INPUT",
            "SOURCE_IP",
            "SOURCE_PASSWORD",
            "MAX_BITRATE",
            "LATENCY",
            "ENCRYPTED",
            "LOCATION",
            "NETWORK",
            "TARGET_BROADCASTER_S",
            "APPLICATION",
            "DEVICE",
            "BUILD",
            "REMOTE_ID",
            "AUTO_PULL",
            "URL",
            "PUBLIC_OUTPUT",
            "RTP_PAYLOAD",
            "PID_MAPPING",
            "TRANSCODING_PROFILE",
            "ALERTING_PROFILE",
            "CLOSED_CAPTION_PRESERVE",
            "ALERT_ON_PID_CHANGES",
            "CONTENT_ANALYSIS",
            "TRACEROUTE_HISTORY",
            "LOG_SCTE_35",
            "SCTE_35_ALERTS"
        ],
        nonDeletedSecondaryDetails: [
            "TYPE",
            "INPUT",
            "SOURCE_IP",
            "SOURCE_PASSWORD",
            "MAX_BITRATE",
            "LATENCY",
            "ENCRYPTED",
            "LOCATION",
            "NETWORK"
        ],
        widgets: [
            //The order is important for the layout
            "STREAM",
            "DIAGRAM",
            "HISTORY",
            "SCTE_35",
            "SOURCES",
            "BONDING",
            "SHARED",
            "ANALYSIS",
            "DETAILS",
            "EVENTS",
            "CHANGES",
            "INCIDENTS",
            "NOTES"
        ]
    } as const;

    protected scopeName = "sourceDetails";
    constants = Constants;
    abstract source: Source;
    abstract canEdit(source: Source): boolean;
    // TODO maybe need to change it like this: something with .bind(this) fill weird to me...
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    // refreshSourcePromise() {}
    abstract refreshSourcePromise(): Promise<Source>;
    constructor(
        protected translate: TranslateService,
        protected graphsService: GraphsService,
        protected userService: UsersService,
        protected sharedService: SharedService
    ) {
        super(userService, sharedService, translate);
    }

    protected translatedNames = {
        ...this.translateList(
            [...this.keys.primaryDetails, ...this.keys.secondaryDetails, ...this.keys.widgets],
            this.translate
        ),
        UP_TIME: `${this.translate.instant("UP_TIME")} [${this.translate.instant("HH:mm:ss")}]`
    };

    protected primaryDetailsDefaultLayout = this.keys.primaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false
    })) satisfies DetailsLayout<typeof this.keys.primaryDetails>[];

    protected sourcePrimaryDetails: Details[] = this.primaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected secondaryDetailsDefaultLayout = this.keys.secondaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false,
        isDeleted: !this.keys.nonDeletedSecondaryDetails.includes(key as any)
    })) satisfies DetailsLayout<typeof this.keys.secondaryDetails>[];

    protected sourceSecondaryDetails: Details[] = this.secondaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected widgetHeadersDefaultLayout = this.keys.widgets.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isSelected: true,
        isHidden: false,
        isExpanded: false
    })) satisfies WidgetHeaderLayout<typeof this.keys.widgets>[];

    protected sourceWidgetHeaders = this.widgetHeadersDefaultLayout.map(layout => ({
        ...layout,
        fa_icon: this.getIconLayoutKey(layout.key)
    })) satisfies WidgetHeader<typeof this.keys.widgets>[];

    protected widgetsDefaultLayout = this.keys.widgets.reduce((obj, key, index) => {
        obj[key] = {
            title: this.translatedNames[key],
            id: this.translatedNames[key],
            key,
            index: index,
            ...this.constants.defaultWidgetLayout
        };
        return obj;
    }, {} as Record<(typeof this.keys.widgets)[number], WidgetLayout>) satisfies Record<
        (typeof this.keys.widgets)[number],
        WidgetLayout
    >;

    protected sourceWidgets: Widget[] = [
        {
            ...this.widgetsDefaultLayout.DETAILS,
            component: SourceDetailsComponent,
            inputs: {
                source: () => this.source,
                canEdit: () => this.canEdit(this.source)
            }
        },
        {
            ...this.widgetsDefaultLayout.DIAGRAM,
            component: ReactFlowDiagramComponent,
            inputs: {
                model: () => this.source,
                type: () => "source"
            }
        },
        {
            ...this.widgetsDefaultLayout.HISTORY,
            component: ZxHistoryGraphComponent,
            inputs: {
                object: () => ({ source: this.source }),
                graphUrl: () => this.graphsService.custom(this.graphsService.source(this.source))
            }
        },
        {
            ...this.widgetsDefaultLayout.EVENTS,
            component: ZxEventsComponent,
            inputs: {
                objects: () => ({ source: this.source }),
                id: () => this.source.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.SOURCES,
            component: SourceSourcesComponent,
            inputs: {
                source: () => this.source,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.ANALYSIS,
            component: SourceContentAnalysisComponent,
            inputs: {
                source: () => this.source
            }
        },
        {
            ...this.widgetsDefaultLayout.CHANGES,
            component: ZxObjectTrackingMainListComponent,
            inputs: {
                objectId: () => this.source.id,
                objectName: () => this.source.name,
                objectType: () => "source",
                objectApiType: () => "sources",
                autoRows: () => false,
                refreshObjectDataFunction: () => this.refreshSourcePromise.bind(this)
            }
        },
        {
            ...this.widgetsDefaultLayout.INCIDENTS,
            component: ObjectIncidentListComponent,
            inputs: {
                objectId: () => this.source.id,
                objectType: () => "source",
                bordered: () => false,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.BONDING,
            component: SourceBondingInfoComponent,
            inputs: {
                source: () => this.source,
                canEdit: () => this.canEdit(this.source)
            }
        },
        {
            ...this.widgetsDefaultLayout.SCTE_35,
            component: ZxScteComponent,
            inputs: {
                source: () => this.source,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.SHARED,
            component: SharedResourceListComponent,
            inputs: {
                activeSharing: () => this.source.activeSharing
            }
        },
        {
            ...this.widgetsDefaultLayout.NOTES,
            component: ZxAdvancedNotesComponent,
            inputs: {
                model: () => this.source,
                type: () => "source",
                id: () => this.source.id,
                canEdit: () => this.canEdit(this.source)
            }
        },
        {
            ...this.widgetsDefaultLayout.STREAM,
            component: SourceStreamComponent,
            inputs: {
                source: () => this.source
            }
        }
    ];

    private getIconLayoutKey(key: (typeof this.keys.widgets)[number]): string | string[] {
        switch (key) {
            case "STREAM":
                return "clapperboard-play";
            case "DETAILS":
                return "align-justify";
            case "DIAGRAM":
                return "sitemap";
            case "SOURCES":
                return "video";
            case "HISTORY":
                return "chart-line";
            case "EVENTS":
                return "exclamation-triangle";
            case "ANALYSIS":
                return "tasks";
            case "CHANGES":
                return "clock-rotate-left";
            case "INCIDENTS":
                return "file-exclamation";
            case "BONDING":
                return "chart-network";
            case "SCTE_35":
                return "clipboard-list";
            case "SHARED":
                return "share";
            case "NOTES":
                return ["far", "sticky-note"];
            default:
                throw new Error("Unknown title"); //TODO should it throw error or return empty string?
        }
    }
}
