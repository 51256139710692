<content-loading-animation *ngIf="loading"></content-loading-animation>
<form
    id="form"
    novalidate
    #form="ngForm"
    (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && !ingestControl.invalid && !multiViewerControl.invalid && onSubmit(form)"
    *ngIf="!loading"
>
    <app-form-title [titles]="['MULTIVIEW', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>
                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>
                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'INPUTS' | translate }}">
                            {{ "INPUTS" | translate }}
                        </h3>
                    </div>

                    <!-- Multiview Type Radio Selection -->
                    <fieldset class="form-group bordered">
                        <legend>{{ "MULTIVIEW_TYPE" | translate }}</legend>
                        <div class="form-check form-check-inline" *ngIf="canZixiMultiview">
                            <input type="radio" id="zixi_type" name="zixi_type" class="form-check-input" value="zixi" [(ngModel)]="source.multiview_type" />
                            <label class="form-check-label" for="zixi_type">{{ "ZIXI_BETA" | translate }} </label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="canTagMultiview">
                            <input
                                type="radio"
                                id="tag_mcm_9000_type"
                                name="tag_mcm_9000_type"
                                class="form-check-input"
                                value="tag-mcm-9000"
                                [(ngModel)]="source.multiview_type"
                            />
                            <label class="form-check-label" for="tag_mcm_9000_type">{{ "TAG" | translate }} </label>
                        </div>
                    </fieldset>

                    <!-- Multiviewer -->
                    <div class="form-group">
                        <label for="multiview_id" [ngClass]="{ 'is-invalid': form.submitted && multiViewerControl.errors }"
                            >{{ "MULTIVIEWER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-multiviewer-select
                            name="multiview_id"
                            [(model)]="source.multiview_id"
                            (multiViewerSelectedChange)="multiViewerSelectedChange()"
                            [multiViewersType]="source.multiview_type"
                            [ngClass]="{ 'is-invalid': form.submitted && multiViewerControl.errors }"
                            required="true"
                        ></zx-multiviewer-select>
                        <div *ngIf="multiViewerControl.invalid" class="invalid-feedback">
                            <div *ngIf="multiViewerControl.errors.required">{{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Layout Type -->
                    <div class="form-group" *ngIf="source.multiview_type === 'tag-mcm-9000'">
                        <label for="layoutType" [ngClass]="{ 'is-invalid': form.submitted && layoutType.errors }"
                            >{{ "LAYOUT" | translate
                            }}<fa-icon icon="asterisk" size="xs"><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></fa-icon
                        ></label>
                        <ng-select
                            class="form-control"
                            id="layoutType"
                            name="layoutType"
                            [items]="layoutTypes"
                            [loading]="multiViewerLoading"
                            bindLabel="title"
                            bindValue="id"
                            placeholder="{{ 'SELECT' | translate }} {{ 'LAYOUT' | translate }}"
                            (change)="layoutTypeChanged($event)"
                            [(ngModel)]="source.multiview_tag_layout_id"
                            [clearable]="false"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && layoutType.errors }"
                            #layoutType="ngModel"
                        >
                            <ng-template ng-label-tmp let-item="item" *ngIf="!multiViewerLoading">
                                {{ item.title }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item" *ngIf="multiViewerLoading"> {{ "LOADING" | translate }}... </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                {{ item.title }}
                            </ng-template>
                        </ng-select>
                        <div *ngIf="layoutType.invalid" class="invalid-feedback">
                            <div *ngIf="layoutType.errors.required">{{ "LAYOUT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Encoder -->
                    <div class="form-group" *ngIf="source.multiview_type === 'tag-mcm-9000'">
                        <label for="outputConfig" [ngClass]="{ 'is-invalid': form.submitted && outputConfig.errors }"
                            >{{ "ENCODER" | translate
                            }}<fa-icon icon="asterisk" size="xs"><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></fa-icon
                        ></label>
                        <ng-select
                            class="form-control"
                            id="outputConfig"
                            name="outputConfig"
                            [items]="outputConfigs"
                            [loading]="multiViewerLoading"
                            bindLabel="title"
                            bindValue="id"
                            placeholder="{{ 'SELECT' | translate }} {{ 'ENCODER' | translate }}"
                            (change)="outputConfigChanged($event)"
                            [(ngModel)]="source.multiview_tag_encoder_id"
                            [clearable]="false"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && outputConfig.errors }"
                            #outputConfig="ngModel"
                        >
                            <ng-template ng-label-tmp let-item="item" *ngIf="!multiViewerLoading">
                                {{ item.title }}
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item" *ngIf="multiViewerLoading"> {{ "LOADING" | translate }}... </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                {{ item.title }}
                            </ng-template>
                        </ng-select>
                        <div *ngIf="outputConfig.invalid" class="invalid-feedback">
                            <div *ngIf="outputConfig.errors.required">{{ "ENCODER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Grid Dimensions -->
                    <div class="row mb-3" *ngIf="source.multiview_type === 'zixi'">
                        <div class="col-6">
                            <label for="gridWidth" [ngClass]="{ 'is-invalid': form.submitted && gridWidth.errors }"
                                >{{ "WIDTH" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="number"
                                id="gridWidth"
                                name="gridWidth"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && gridWidth.errors }"
                                [(ngModel)]="source.multiview_width"
                                required
                                max="4"
                                #gridWidth="ngModel"
                            />
                            <div *ngIf="gridWidth.invalid" class="invalid-feedback">
                                <div *ngIf="gridWidth.errors.required">{{ "WIDTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="gridHeight" [ngClass]="{ 'is-invalid': form.submitted && gridHeight.errors }"
                                >{{ "HEIGHT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <input
                                type="number"
                                id="gridHeight"
                                name="gridHeight"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && gridHeight.errors }"
                                [(ngModel)]="source.multiview_height"
                                required
                                max="4"
                                #gridHeight="ngModel"
                            />
                            <div *ngIf="gridHeight.invalid" class="invalid-feedback">
                                <div *ngIf="gridHeight.errors.required">{{ "HEIGHT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                    </div>

                    <!-- Sources -->
                    <app-multiview-source-selection
                        *ngIf="source.multiview_type === 'zixi' || selectedLayoutType"
                        [formSubmitted]="form.submitted"
                        [maxX]="source.multiview_width"
                        [maxY]="source.multiview_height"
                        [selectedSources]="multiviewSources"
                        [maxTotal]="source.multiview_type === 'zixi' ? undefined : selectedLayoutType?.LayoutTypeBoxes?.length"
                    ></app-multiview-source-selection>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                            {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && ingestControl.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && ingestControl.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id, true)"
                        ></zx-cluster-select>
                        <div *ngIf="ingestControl.invalid" class="invalid-feedback">
                            <div *ngIf="ingestControl.errors.required">{{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            (change)="getTargetBroadcasterDetails(source.target_broadcaster_id)"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Transcoding Profile -->
                    <div class="form-group" *ngIf="source.multiview_type === 'zixi'">
                        <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                            >{{ "ENCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-transcoding-profile-select
                            name="transcodingProfile"
                            [(model)]="transcodeProfile"
                            required="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                        ></zx-transcoding-profile-select>
                        <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.transcodingProfile?.errors.required">
                                {{ "ENCODING_PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>

                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showAutoMTU]="true"
                        [showAutopullBilling]="true"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                        [showBilling]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                    ></app-advanced-section-form>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />

                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{
                                'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid || ingestControl.invalid)
                            }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
