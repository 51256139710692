import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";
import { TranslateService } from "@ngx-translate/core";

import { FailoverRule, Source } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { createDefaultRuleControl } from "../../channels/channel-form-failover-channel/failover-rules/failover-rules.component";
import { FailoverErrorConcealmentData } from "../../channels/channel-form-failover-channel/error-concealment/error-concealment.component";

type FailoverComponentSetting = {
    source: Source;
    priority: number;
    min_bitrate: number;
};

@Component({
    selector: "app-source-form-failover",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormFailoverComponent implements OnInit {
    source: Source;
    private sources: Source[];
    sourceNames: string[];

    private existingSource: Source;
    private action: string;
    loading = true;
    saving = false;

    isEdit = false;
    isClone = false;
    startDisabled = false;
    showMoreOptions = false;
    isSubmitted = false;

    constants = Constants;

    p1Group = createDefaultRuleControl(this.saving);
    frozenVideoGroup = createDefaultRuleControl(this.saving);
    blancPicGroup = createDefaultRuleControl(this.saving);
    silentAudioGroup = createDefaultRuleControl(this.saving);
    lowBitrateGroup = createDefaultRuleControl(this.saving, true);
    mappedGroups = [
        [this.p1Group, "p1_transport"],
        [this.frozenVideoGroup, "frozen_video"],
        [this.blancPicGroup, "blank_picture"],
        [this.silentAudioGroup, "silent_audio"],
        [this.lowBitrateGroup, "low_bitrate"]
    ] as const;

    selectedFailoverSources: FailoverComponentSetting[] = [];
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private ss = inject(SourcesService);
    private broadcastersService = inject(BroadcastersService);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    private targetBXsBase = this.targetBXs;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    errorConcealmentData: FailoverErrorConcealmentData = {
        error_concealment: 0,
        error_concealment_continuous_timeline: 0,
        error_concealment_replace_frames: 0,
        error_concealment_delay_ms: 1000,
        error_concealment_cbr_padding_kbps: null,
        error_concealment_cbr_padding_pcr_interval_ms: 100,
        error_concealment_fix_cc: 0
    };

    private prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            this.initShowMoreFailoverOptions();
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                if (this.source.broadcaster_cluster_id) {
                    this.clusterSelectionChange(this.source.broadcaster_cluster_id, false);
                }

                if (this.source.failoverSources) {
                    this.selectedFailoverSources = (this.source.failoverSources ?? []).map(fs => ({
                        min_bitrate: Math.floor(fs.min_bitrate / 1000),
                        source: fs.source,
                        priority: fs.priority
                    }));

                    this.sources = this.sources.filter(
                        source => !_.find(this.selectedFailoverSources, fs => fs.source.id === source.id)
                    );
                }
                this.errorConcealmentData = {
                    error_concealment: this.source.error_concealment,
                    error_concealment_continuous_timeline: this.source.error_concealment_continuous_timeline,
                    error_concealment_replace_frames: this.source.error_concealment_replace_frames,
                    error_concealment_delay_ms: this.source.error_concealment_delay_ms,
                    error_concealment_cbr_padding_kbps: this.source.error_concealment_cbr_padding_kbps,
                    error_concealment_cbr_padding_pcr_interval_ms:
                        this.source.error_concealment_cbr_padding_pcr_interval_ms,
                    error_concealment_fix_cc: this.source.error_concealment_fix_cc
                };

                /* for (const rule of this.source.failoverRules || []) {
                    this.failoverRules[rule.rule] = {
                        ...rule,
                        enabled: true,
                        min_bitrate: Math.floor(rule.min_bitrate / 1000)
                    };
                } */
                this.updateGroups(this.source.failoverRules);
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.source.type = "hitless";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    private resetForm() {
        // Source
        this.source.mediaconnect_mode = "pull";
        this.source.latency = 1000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.traceroute_history = 0;
        this.source.output_nic = "";
        this.tagsControl.setValue([]);
        this.source.broadcaster_cluster_id = null;
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.source.transcode_source_id = null;
        this.nameControl.setValue(null);
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.transcode_profile_id = null;
        this.source.transcode_cbr_kbps = null;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        this.source.merge_mode = "none";
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.source.allow_failover_priority_upgrade = false;
        // this.source.webrtc_thumbnail = 0;
        // UI
        this.selectedFailoverSources = [];
    }

    async clusterSelectionChange(id: number, clusterChanged: boolean) {
        //  Clear specific broadcser selection since cluster is about to change.
        if (clusterChanged && this.source && this.source.target_broadcaster_id >= 0)
            this.source.target_broadcaster_id = undefined;

        this.getClusterSourceNames(id);
        this.getTargetBroadcasters(id);
    }

    private getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    private async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }
        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (broadcasters && broadcasters.length > 0) {
            this.targetBXs = this.targetBXsBase.concat(
                _.map(broadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }
        this.targetBXsLoading = false;
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const sourceName = params.name;
        const sourceId = urlBuilder.decode(params.sourceId);
        this.action = params.action;

        // Sources
        await firstValueFrom(this.ss.refreshSources(true));
        const sources = await firstValueFrom(this.ss.sources);
        this.sources = sources.filter(s => s.type === "hitless" && s.id !== sourceId);

        if (sourceName && sourceId) {
            this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));

            // Check if source found in cache, if not get sources and source
            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                await firstValueFrom(this.ss.refreshSource(this.source));
                this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));
            }
            this.existingSource = _.cloneDeep(this.source);
        }

        this.prepForm();
        this.loading = false;
    }

    async onSubmit() {
        this.isSubmitted = true;
        if (this.selectedFailoverSources.length < 2) return;

        this.saving = true;
        const sourceModel = {
            name: this.isEdit ? undefined : this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            feeder_id: null,
            broadcaster_id: null,
            target_broadcaster_id: this.source.target_broadcaster_id,
            input_id: null,
            max_bitrate: Math.max.apply(
                null,
                this.selectedFailoverSources.map(s => s.source.max_bitrate)
            ),

            latency: this.source.latency,
            monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
            traceroute_history: this.source.traceroute_history ? 1 : 0,
            content_analysis: this.source.content_analysis ? 1 : 0,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            password: "",
            encryption: "none",
            encryption_key: "",
            allow_outputs: this.source.allow_outputs ? 1 : 0,
            outputs_password: this.source.outputs_password || "",
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            merge_mode: this.source.merge_mode,
            webrtc_mode: this.source.webrtc_mode,
            location: this.source.location.address,
            muted: this.source.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.source.is_enabled,
            // webrtc_thumbnail: this.source.webrtc_thumbnail
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            keep_rtp_headers: this.source.keep_rtp_headers,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            allow_failover_priority_upgrade: this.source.allow_failover_priority_upgrade,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion,
            failoverSources: this.selectedFailoverSources.map(fs => {
                return {
                    source_id: fs.source.id,
                    priority: fs.priority,
                    min_bitrate: fs.min_bitrate * 1000
                };
            }),
            failoverRules: this.getGroupData(),
            ...this.errorConcealmentData
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(sourceModel, this.existingSource, []);
            const newSources = this.compareSources(this.existingSource, sourceModel);
            if (newSources) {
                Object.assign(changedData, { failoverSources: newSources });
            } else {
                delete changedData.failoverSources;
            }
            const newRules = this.compareRules(this.existingSource, sourceModel);
            if (newRules) {
                Object.assign(changedData, { failoverRules: newRules });
            } else {
                delete changedData.failoverRules;
            }

            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart hitless failover source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update hitless failover source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(sourceModel);
            if (result) {
                this.mixpanelService.sendEvent("create hitless failover source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    private compareSources(existingSource: Source, model) {
        const reducedOriginal = _.map(existingSource.failoverSources, fs => {
            return _.pick(fs, "priority", "source_id", "min_bitrate");
        });
        if (_.isEqual(reducedOriginal, model.failoverSources)) {
            return false;
        } else {
            return model.failoverSources;
        }
    }

    private compareRules(existingChannel: Source, model) {
        const reducedOriginal = _.map(existingChannel.failoverRules, fs => {
            return _.pick(fs, "rule", "sample_duration", "samples", "samples_threshold", "pid", "min_bitrate");
        });
        if (_.isEqual(reducedOriginal, model.failoverRules)) {
            return false;
        } else {
            return model.failoverRules;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    private initShowMoreFailoverOptions(): void {
        if (this.isEdit || this.isClone) {
            this.showMoreOptions = this.source.merge_mode !== "none";
        }
    }

    private updateGroups(rules: FailoverRule[]) {
        if (!rules) return;
        for (const [group, ruleName] of this.mappedGroups) {
            const rule = rules.find(r => r.rule === ruleName);
            if (!rule) continue;
            group.enabledControl.setValue(true);
            group.controls.sample_duration.control.setValue(rule.sample_duration);
            group.controls.samples.control.setValue(rule.samples);
            group.controls.pid.control.setValue(rule.pid);
            group.controls.samples_threshold.control.setValue(rule.samples_threshold);
            if (group.controls.min_bitrate) group.controls.min_bitrate.control.setValue(rule.min_bitrate);
        }
    }

    private getGroupData(): FailoverRule[] {
        return this.mappedGroups
            .filter(([group]) => group.enabledControl.value)
            .map(([group, ruleName]) => ({
                rule: ruleName,
                sample_duration: group.controls.sample_duration.control.value,
                samples: group.controls.samples.control.value,
                pid: group.controls.pid.control.value || null,
                samples_threshold: group.controls.samples_threshold.control.value,
                min_bitrate: group.controls.min_bitrate?.control.value || null
            }));
    }
}
