import { TitleCasePipe } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { TitleService } from "../../../services/title.service";

import { Constants } from "../../../constants/constants";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { SharedService } from "src/app/services/shared.service";
import { Tag } from "src/app/models/shared";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-source-form",
    templateUrl: "./source-form.component.html",
    providers: [TitleCasePipe],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormComponent implements OnInit {
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name)
    ]);

    constants = Constants;

    mediaconnectResourceTags: Tag[] = [];
    sourceResourceTags: Tag[] = [];
    pidResourceTags: Tag[] = [];
    private router = inject(Router);
    private titleService = inject(TitleService);
    private sharedService = inject(SharedService);

    async ngOnInit() {
        this.titleService.setTitle("SOURCE");
        // resourceTags
        const [sourceTags, pidTags, mediaconnectTags] = await Promise.all(
            ["source", "pid_mapping_profiles", "mediaconnect_sources"].map(type =>
                firstValueFrom(this.sharedService.getResourceTagsByType(type))
            )
        );

        this.sourceResourceTags = sourceTags;
        this.pidResourceTags = pidTags;
        this.mediaconnectResourceTags = mediaconnectTags;
    }

    goto(type: string) {
        this.router.navigate([Constants.urls.sources, type, "new"]);
    }

    cancel() {
        this.router.navigate([Constants.urls.sources]);
    }
}
