import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { SourceListComponent } from "./source-list/source-list.component";
import { SourceComponent } from "./source/source.component";
import { SourceFormComponent } from "./source-form/source-form.component";
import { SourceFormZixiComponent } from "./source-form-zixi/source-form.component";
import { SourceFormToMediaConnectComponent } from "./source-form-to-mediaconnect/source-form.component";
import { SourceFormMonitorComponent } from "./source-form-monitor/source-form.component";
import { SourceFormFileComponent } from "./source-form-file/source-form.component";
import { SourceFormCommonFieldsComponent } from "./source-form-common/source-form-common.component";
import { SourceFormTranscodedComponent } from "./source-form-transcoded/source-form.component";
import { SourceFormNdiComponent } from "./source-form-ndi/source-form.component";
import { SourceFormFailoverComponent } from "./source-form-failover/source-form.component";
import { SourceFormInterClusterComponent } from "./source-form-inter-cluster/source-form.component";
import { SourceBondingInfoComponent } from "./source/source-bonding-info/source-bonding-info.component";
import { SourceStreamInfoComponent } from "./source/source-stream-info/source-stream-info.component";
import { MediaConnectSourceComponent } from "./mc-source/mc-source.component";
import { SourceFormUDPRTPComponent } from "./source-form-udp-rtp/source-form.component";
import { SourceFormSRTComponent } from "./source-form-srt/source-form.component";
import { SourceFormRTMPComponent } from "./source-form-rtmp/source-form.component";
import { SourceFormHLSPullComponent } from "./source-form-hls-pull/source-form.component";
import { SourceFormRISTComponent } from "./source-form-rist/source-form.component";
import { SourceFormPidMappingComponent } from "./source-form-pid-mapping/source-form.component";
import { MediaConnectSourceContentAnalysisComponent } from "./mc-source/mc-source-content-analysis/mc-source-content-analysis.component";
import { SourceElementalLinkComponent } from "./source-form-elemental-link/source-form.component";
import { SourceFormMultiviewComponent } from "./source-form-multiview/source-form.component";
import { SourceFormDemuxComponent } from "./source-form-demux/source-form.component";
import { SourceFormMultiplexComponent } from "./source-form-multiplex/source-form.component";

// Routes
import { SourcesRouting } from "./sources.routing";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { SourceStreamComponent } from "./source/source-stream/source-stream.component";
import { SourceConfigDialogComponent } from "src/app/pages/sources/source/source-config-dialog/source-config-dialog.component";
import { SourceTracerouteDialogComponent } from "./source/source-traceroute-dialog/source-traceroute-dialog.component";
import { MediaConnectSourceConfigDialogComponent } from "./mc-source/mc-source-config-dialog/mc-source-config-dialog.component";
import { MediaConnectSourceStreamComponent } from "./mc-source/mc-source-stream/mc-source-stream.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        SourcesRouting,
        MatMenuModule,
        MatTooltipModule,
        TourMatMenuModule
    ],
    declarations: [
        SourceListComponent,
        SourceComponent,
        SourceFormComponent,
        SourceFormZixiComponent,
        SourceFormToMediaConnectComponent,
        SourceFormMonitorComponent,
        SourceFormFileComponent,
        SourceFormCommonFieldsComponent,
        SourceFormTranscodedComponent,
        SourceFormNdiComponent,
        SourceFormFailoverComponent,
        SourceFormInterClusterComponent,
        SourceBondingInfoComponent,
        MediaConnectSourceContentAnalysisComponent,
        SourceStreamInfoComponent,
        SourceStreamComponent,
        MediaConnectSourceStreamComponent,
        MediaConnectSourceComponent,
        SourceFormUDPRTPComponent,
        SourceFormSRTComponent,
        SourceFormRISTComponent,
        SourceFormPidMappingComponent,
        SourceFormRTMPComponent,
        SourceFormHLSPullComponent,
        SourceElementalLinkComponent,
        SourceFormMultiviewComponent,
        SourceFormDemuxComponent,
        SourceFormMultiplexComponent,
        SourceConfigDialogComponent,
        MediaConnectSourceConfigDialogComponent,
        SourceTracerouteDialogComponent
    ]
})
export class SourcesModule {}
